.custom-quill-text .ql-editor {
  font-size: 1.1rem; /* Adjust this value as needed */
  line-height: 1.4; /* Optional: Adjust line spacing */
}

.custom-quill-text .ql-syntax {
  background-color: #282c34 !important;
  /* Dark background for code blocks */
  color: #61dafb !important;
  /* Light blue text for code */
  padding: 10px !important;
  /* Add padding for better readability */
  border-radius: 5px !important;
  /* Rounded corners */
  font-family: 'Courier New', Courier, monospace !important;
  /* Monospace font */
  overflow-x: auto !important;
  /* Allow horizontal scrolling for long code lines */
}

.custom-quill-text .code-block {
  background-color: #282c34 !important;
  /* Dark background for code blocks */
  color: #61dafb !important;
  /* Light blue text for code */
  padding: 10px !important;
  /* Add padding for better readability */
  border-radius: 5px !important;
  /* Rounded corners */
  font-family: 'Courier New', Courier, monospace !important;
  /* Monospace font */
  overflow-x: auto !important;
  /* Allow horizontal scrolling for long code lines */
}

.custom-quill-text .ql-syntax,
.custom-quill-text .code-block {
  position: relative; /* Ensure the parent is positioned */
  padding-top: 40px; /* Add space at the top for the button */
}

.custom-quill-text .ql-syntax::before,
.custom-quill-text .code-block::before {
  content: "Copy Code"; /* Text for the button */
  display: block;
  background-color: #10d915; /* Green background */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  width: fit-content; /* Make the button width fit its content */
  font-family: Arial, sans-serif; /* Use a readable font */
  font-size: 0.9rem;
  cursor: pointer;
  position: absolute; /* Position the button absolutely */
  top: 10px; /* Adjust top position */
  right: 10px; /* Position the button on the right */
  z-index: 1;
}

/* Optional: Add hover effect */
.custom-quill-text .ql-syntax::before:hover,
.custom-quill-text .code-block::before:hover {
  background-color: #34a039; /* Darker green on hover */
}
